<template>
  <layout width="540">
    <template slot="header"> {{ title }} </template>
    <template>
      <validation-observer ref="form">
        <v-form>
          <validation-provider
            v-slot="{ errors }"
            name="Object name"
            rules="required"
          >
            <v-text-field
              v-model.trim="state.name"
              label="Object name*"
              :error-messages="errors"
            />
          </validation-provider>
          <validation-provider
            v-if="!edit"
            v-slot="{ errors }"
            name="Schema type"
            rules="required"
          >
            <v-select
              v-model="state.schemaType"
              :items="schemaTypes"
              :error-messages="errors"
              item-text="name"
              item-value="id"
              label="Schema type*"
            />
          </validation-provider>
          <validation-provider
            v-if="!edit"
            v-slot="{ errors }"
            name="Schema name"
            rules="required"
          >
            <v-autocomplete
              v-model="state.schemaId"
              :items="schemas"
              :loading="schemasLoading"
              :error-messages="errors"
              item-text="name"
              item-value="id"
              label="Schema name*"
            />
          </validation-provider>
          <form-switcher v-model="state.enabled" title="Enabled" />
          <form-subheader title="Description" />
          <v-textarea
            v-model="state.description"
            no-resize
            rows="1"
            auto-grow
            label="Description"
          />
        </v-form>
      </validation-observer>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="exec"
      >
        {{ submitBtnTitle }}
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { ref, computed, onMounted, watch } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { linkedObjectService } from '@/modules/linked-objects/api';
import { schemasService, objectService } from '@/modules/common/api';
import { required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { abcSort } from '@/compositions/sortBy';

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  name: 'LinkedObjectEdit',
  components: {
    Layout,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    objectId: {
      type: String,
      default: ''
    },
    linkedObjectId: {
      type: String,
      default: ''
    },
    typeObject: {
      type: String,
      default: 'device'
    }
  },
  setup(props, { emit }) {
    const schemaTypesDefinition = [
      {
        id: 'DEVICE',
        name: 'Device'
      },
      {
        id: 'DATASET',
        name: 'Dataset'
      }
    ];

    const state = ref({
      id: '',
      name: '',
      description: '',
      schemaId: '',
      schemaType: schemaTypesDefinition[0].id,
      enabled: true
    });

    const edit = computed(() => props.linkedObjectId);
    const title = computed(() => `${edit.value ? 'Edit' : 'Add'}  object`);
    const form = ref(null);

    const submitBtnTitle = computed(() => (edit.value ? 'Save' : 'Add'));

    const schemaTypes = ref(schemaTypesDefinition);

    const submit = async () => {
      form.value.validate().then(async success => {
        console.log(success);
        if (!success) {
          return;
        }

        if (!edit.value) {
          await linkedObjectService
            .create({
              schemaId: state.value.schemaId,
              name: state.value.name,
              enabled: state.value.enabled,
              description: state.value.description
            })
            .then(async r => {
              const createdObjectId = r.data.createObject?.object.id;
              await objectService.link(props.objectId, createdObjectId);
            });
        } else {
          await linkedObjectService.update(props.linkedObjectId, {
            name: state.value.name,
            enabled: state.value.enabled,
            description: state.value.description
          });
        }
        emit('submit');
        emit('close');
      });
    };

    const {
      exec: updateSchemas,
      loading: schemasLoading,
      result: schemas
    } = usePromise(
      () =>
        schemasService.fetch(state.value.schemaType, true, {
          not: {
            mTags: {
              contains: 'monitor'
            }
          }
        }),
      true
    );

    watch(
      () => state.value.schemaType,
      () => {
        updateSchemas();
        state.value.schemaId = '';
      },
      {
        immediate: true
      }
    );

    onMounted(async () => {
      if (edit.value) {
        const device = await linkedObjectService.fetch(props.linkedObjectId);
        if (device) {
          state.value.name = device.name;
          state.value.id = device.id;
          state.value.description = device.description || '';
          state.value.enabled = device.enabled;
        }
      }
    });

    const { loading, exec } = usePromise(submit, true);

    return {
      submitBtnTitle,
      edit,
      state,
      exec,
      loading,
      schemasLoading,
      schemas: computed(() =>
        (schemas.value || []).sort((a, b) => abcSort(a.name, b.name))
      ),
      form,
      schemaTypes,
      title
    };
  }
};
</script>
